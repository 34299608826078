<template>
    <section id="page-title" :class="{ 'page-title-mini' : computedOptions.mini }">

			<div class="container clearfix">
				<h1> {{ getLocalizedText(labels.title) }}</h1>
				<span v-if="labels.subtitle"> {{ getLocalizedText(labels.subtitle) }} </span>
        <!--
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><a href="#">Home</a></li>
					<li class="breadcrumb-item"><a href="#">Pages</a></li>
					<li class="breadcrumb-item active" aria-current="page">About Us</li>
				</ol>
        -->
			</div>

		</section>
</template>

<script lang="ts">
import { CmsContent, CmsEnum, CmsLabel, CmsBoolean, CmsPicture, CmsText, CmsFile, CmsNumber } from '@fwk-client/modules/cms/types';
import { defineComponent, PropType } from '@fwk-node-modules/vue'
import { languagesTypes } from '@fwk-client/store/types';
import { getApp, useCmsModule, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

/** @cmsOptions */
export interface PageTitleOptions {
    /** @cmsType CmsBoolean */
    mini?:CmsBoolean
}

/** @cmsLabels */
export interface PageTitleLabels {
    /** @cmsType CmsLabel */
    title?:CmsLabel
    /** @cmsType CmsLabel */
    subtitle?:CmsLabel
}

/** @cmsSlots */
export interface PageTitleSlots {
    
}


export default defineComponent({
    props: {
        options: Object as PropType<PageTitleOptions>,
        labels: {
          type: Object as PropType<PageTitleLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<PageTitleSlots>
    },
    components : {
    },
    setup(props, context) {

        const app = getApp();
        const $router = useRouter();
        const $store = useStore();
        const cms = useCmsModule();

        const computedOptions:PageTitleOptions = {
            mini:false,
            ...props.options
        };

        const computedLabels:PageTitleLabels = {
            ...props.labels
        }

        return {
            computedOptions: {
                ...computedOptions
            },
            labels:computedLabels
        }

    }
})
</script>